<template>
	<div class="export-report-content">
		<div class="page-one">
			<div class="logo">
				<span class="logo-1"></span>
				<span class="logo-2"></span>
			</div>
			<p class="company">吐鲁番昌安公共交通公司</p>
			<p class="title">驾驶行为分析</p>
			<p class="date">报告日期：{{ currentDay }}</p>
		</div>
		<div class="page-2">
			<div class="base-info">
				<div class="card-title">
					<div class="left">
						<span class="icon"></span>
						<span class="text">基本信息</span>
					</div>
				</div>
				<div class="card-content">
					<div class="table-content">
						<div class="driver-info">
							<div class="info-item">
								<span class="label">司机姓名</span>
								<span class="value">{{ dataObj?.driverInfoVo?.driverName }}</span>
							</div>
							<div class="info-item">
								<span class="label">性别</span>
								<span class="value">{{ dataObj?.driverInfoVo?.gender == "0" ? "女" : "男" }}</span>
							</div>
							<div class="info-item">
								<span class="label">年龄</span>
								<span class="value">{{ dataObj?.driverInfoVo?.birth | calcAge }}</span>
							</div>
							<div class="info-item">
								<span class="label">在职时间</span>
								<span class="value">{{ dataObj?.driverInfoVo?.entryDate | calcWorkTime }}</span>
							</div>
							<div class="info-item">
								<span class="label">驾驶里程</span>
								<span class="value">-</span>
							</div>
							<div class="info-item">
								<span class="label">近三个月出勤率</span>
								<span class="value">-</span>
							</div>
							<div class="info-item">
								<span class="label">健康状态</span>
								<span class="value">-</span>
							</div>
							<div class="info-item">
								<span class="label">近一年违章次数</span>
								<span class="value">-</span>
							</div>
						</div>
						<div class="dirver-avator"></div>
					</div>
				</div>
			</div>
			<div class="driver-behavior">
				<div class="card-title">
					<div class="left">
						<span class="icon"></span>
						<span class="text">司机近期驾驶行为</span>
					</div>
					<div class="right">
						<span class="text time-text">时间：</span>
						<!-- <div class="time-area"> -->
						<span class="text">{{ pre3Month }}</span>
						<!-- <span class="icon"></span> -->
						<!-- </div> -->
						<span class="text con-text">至</span>
						<!-- <div class="time-area"> -->
						<span class="text">{{ currentMonth }}</span>
						<!-- <span class="icon"></span> -->
						<!-- </div> -->
					</div>
				</div>
				<div class="card-content">
					<div class="card-left">
						<p class="title">司机驾驶行为报警分析</p>
						<div class="bar-echarts" id="barExportEcharts"></div>
					</div>
					<div class="card-right">
						<div class="table-content">
							<div class="t-head">
								<span class="per-1">序号</span>
								<span class="per-2">行为</span>
								<span class="per-3">次数</span>
								<span class="per-4">占本线路报警比例</span>
							</div>
							<div class="t-body">
								<div
									class="data-item"
									v-for="(item, index) in dataObj?.vehicleDetailVoList || []"
									:key="index"
									:class="index % 2 !== 0 ? 'bg' : ''"
								>
									<!-- eslint-disable-next-line -->
									<div class="per-1">
										{{ index + 1 }}
									</div>
									<div class="per-2">
										{{ item.behavior }}
									</div>
									<div class="per-3">
										{{ item.count || 0 }}
									</div>
									<div class="per-4">
										{{ item.routeProportion || 0 }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="driver-ranking">
				<div class="card-title">
					<div class="left">
						<span class="icon"></span>
						<span class="text">驾驶行为习惯图谱及排名</span>
					</div>
					<div class="right">
						<div class="line">
							<span class="text time-text">线路：</span>
							<span class="text value">{{ rowData.routeName }}</span>
						</div>
						<span class="text time-text">时间</span>
						<!-- <div class="time-area"> -->
						<span class="text">{{ pre3Month }}</span>
						<!-- <span class="icon"></span> -->
						<!-- </div> -->
						<span class="text con-text">至</span>
						<!-- <div class="time-area"> -->
						<span class="text">{{ currentMonth }}</span>
						<!-- <span class="icon"></span> -->
						<!-- </div> -->
					</div>
				</div>
				<div class="card-content">
					<div class="card-left">
						<div class="radar-echarts" id="radarExportEcharts"></div>
					</div>
					<div class="card-right">
						<div class="table-content">
							<div class="t-head">
								<span class="per-1">序号</span>
								<span class="per-2">行为</span>
								<span class="per-3">扣分</span>
								<span class="per-4">排名</span>
							</div>
							<div class="t-body">
								<div
									class="data-item"
									v-for="(item, index) in dataObj?.routeDetailVoList || []"
									:key="index"
									:class="index % 2 !== 0 ? 'bg' : ''"
								>
									<!-- eslint-disable-next-line -->
									<div class="per-1">
										{{ index + 1 }}
									</div>
									<div class="per-2">
										{{ item.behavior }}
									</div>
									<div class="per-3">
										{{ item.deductionScore || 0 }}
									</div>
									<div class="per-4">
										{{ item.routeRanking || "-" }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="analys-result">
				<div class="card-title">
					<div class="left">
						<span class="icon"></span>
						<span class="text">驾驶行为分析结论</span>
					</div>
					<div class="right">
						<span class="level-text">等级【</span>
						<span class="level-text hignli-text">{{ rankingMap.text }}</span>
						<span class="level-text">】</span>
					</div>
				</div>
				<div class="card-content">
					<div class="card-left">
						<div class="ring-charts" id="ringExportEcharts"></div>
						<p class="title">综合得分</p>
					</div>
					<div class="card-right">
						<div class="rank-p">
							<div class="rank-item">
								<span class="icon"></span>
								<span class="label">在线路的排名：</span>
								<span class="value">{{ dataObj.routeRanking || 0 }}/{{ dataObj.routeTotal || 0 }}</span>
							</div>
							<div class="rank-item rank-right">
								<span class="icon"></span>
								<span class="label">在公司的排名：</span>
								<span class="value">{{ dataObj.companyRanking || 0 }}/{{ dataObj.companyTotal || 0 }}</span>
							</div>
						</div>
						<div class="result-area">
							<div class="result-text">
								通过监测驾驶员的踩油门与刹车的习惯、起步与停车的平稳性等，该时间段内该司机综合得分为【{{
									dataObj.totalScore ? dataObj.totalScore.toFixed(1) : 0
								}}】分；在【{{ rowData.routeName }}】线路中排名【第{{
									dataObj.routeRanking || 0
								}}】，在【吐鲁番昌安公共交通有限公司】中排名【第{{
									dataObj.companyRanking || 0
								}}】，综合以上驾驶行为习惯分析，近三个月驾驶等级评定【{{ rankingMap.text }}】。
							</div>
							<div class="suggest" v-if="dataObj?.suggestions?.length">
								<p class="result-text">建议司机按以下驾驶方式优化：</p>
								<p class="text" v-for="(item, index) in dataObj.suggestions || []" :key="index">{{ index + 1 }}.{{ item }}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="end-text">吐鲁番昌安公共交通公司</div>
		</div>
	</div>
</template>

<script>
import dayjs from "dayjs";

export default {
	data() {
		return {
			chartsData: [],
			departChartData: [],
			barEchartsOptionsXData: [],
			departIndicator: [],
			currentDay: "",
			currentMonth: "",
			pre3Month: "",
			dataObj: {},
			ringEchartsOptions: {
				title: {
					text: "",
					x: "center",
					y: "center",
					textStyle: {
						rich: {
							num: {
								fontWeight: 500,
								color: "#000",
								fontSize: 26
							},
							unit: {
								color: "#767D91",
								fontSize: 15
							}
						}
					}
				},
				legend: {
					show: false
				},
				series: [
					{
						name: "",
						type: "pie",
						radius: ["80%", "90%"],
						avoidLabelOverlap: false,
						hoverAnimation: false,
						label: {
							show: false
						},
						emphasis: {
							scale: false
						},
						labelLine: {
							show: false
						},
						itemStyle: {
							borderRadius: 3
						},
						data: []
					}
				]
			},
			barEchartsOptions: {
				grid: {
					left: 10,
					right: 10,
					bottom: 0,
					top: 30,
					containLabel: true
				},
				tooltip: {
					trigger: "axis",
					axisPointer: {
						type: "shadow"
					}
				},
				xAxis: {
					data: ["急加速", "急减速", "开门行车", "超速行驶", "空挡空调", "满油门起步", "拉手刹3分钟\n不回空挡"],
					axisLine: {
						lineStyle: {
							color: "rgba(24, 24, 24, 0.25)"
						}
					},
					axisLabel: {
						color: "rgba(0, 0, 0, 0.6)",
						fontSize: 8,
						interval: 0,
						lineHeight: 11
					},
					axisTick: {
						alignWithLabel: true
					}
				},
				yAxis: {
					name: "单位（次）",
					nameTextStyle: {
						color: "rgba(0, 0, 0, 0.4)",
						fontSize: 9
					},
					axisLine: {
						show: false,
						lineStyle: {
							color: "rgba(24, 24, 24, 0.1)"
						}
					},
					axisLabel: {
						color: "rgba(0, 0, 0, 0.4)",
						fontSize: 8
					},
					splitLine: {
						// show: false,
						lineStyle: {
							color: "rgba(24, 24, 24, 0.1)"
						}
					}
				},
				series: [
					{
						type: "bar",
						barWidth: 9,
						itemStyle: {
							normal: {
								color: "#0052D9"
							}
						},
						data: []
					}
				]
			},
			radarEchartsOptions: {
				color: ["#5B8FF9"],
				radar: {
					indicator: [
						{ name: "急加速", max: 10, axisLabel: { show: true } },
						{ name: "急减速", max: 10 },
						{ name: "开门行车", max: 10 },
						{ name: "超速行驶", max: 10 },
						{ name: "空挡空调", max: 10 },
						{ name: "满油门起步", max: 10 },
						{ name: "拉手刹3分钟不回空挡", max: 10 }
					]
				},
				series: [
					{
						name: "",
						type: "radar",
						data: [
							{
								value: []
								// name: "急加速"
							}
						],
						areaStyle: {
							color: "rgba(91, 143, 249, 0.25)"
						}
					}
				]
			},
			ringColorMap: {
				"tag-1": "#F76763",
				"tag-2": "#5B80FE",
				"tag-3": "#5B80FE",
				"tag-4": "#04BDA1"
			}
		};
	},
	props: {
		rowData: {
			type: Object,
			default: () => ({})
		}
	},
	computed: {
		rankingMap() {
			const value = this.dataObj.totalScore;
			if (value < 60) {
				return {
					text: "一般",
					className: "tag-1"
				};
			} else if (value >= 60 && value < 80) {
				return {
					text: "良好",
					className: "tag-2"
				};
			} else if (value >= 80 && value < 90) {
				return {
					text: "优秀",
					className: "tag-3"
				};
			} else {
				return {
					text: "非常优秀",
					className: "tag-4"
				};
			}
		}
	},
	filters: {
		calcAge(val) {
			if (val) {
				return dayjs().diff(val, "year");
			} else {
				return "-";
			}
		},
		calcWorkTime(val) {
			if (val) {
				return dayjs().diff(val, "day") + "天";
			} else {
				return "-";
			}
		}
	},
	async mounted() {
		this.initTime();
		await this.initData();
		this.formatData();
	},
	methods: {
		async initData() {
			const url = "/crrc-palm-bus/drivingBehavior/findStat";
			const { routeId, vehicleFrameNo, driverId } = this.rowData;
			const params = {
				startDate: dayjs(this.pre3Month).startOf("month").format("YYYYMMDD"),
				endDate: dayjs(this.currentMonth).endOf("month").format("YYYYMMDD"),
				routeId,
				vin: vehicleFrameNo,
				driverId
			};
			const res = await this.$http.post(url, params, { baseType: "base" });
			this.dataObj = res || {};
			this.getEchartsData();
		},
		initTime() {
			this.currentDay = dayjs().format("YYYYMMDD");
			this.currentMonth = dayjs().format("YYYY-MM");
			this.pre3Month = dayjs().subtract(3, "month").format("YYYY-MM");
		},
		getEchartsData() {
			this.chartsData = [];
			const vehicleDetailVoList = this.dataObj?.vehicleDetailVoList || [];
			const routeDetailVoList = this.dataObj?.vehicleDetailVoList || [];
			this.chartsData = vehicleDetailVoList.map(item => item.count || 0);
			this.barEchartsOptionsXData = vehicleDetailVoList.map(item => {
				const text = item.behavior;
				if (text.length > 5) {
					const arr = text.split("");
					arr.splice(5, 0, "\n");
					return arr.join("");
				}
				return text;
			});
			this.departChartData = routeDetailVoList.map(item => item.count || 0);
			this.departIndicator = routeDetailVoList.map((item, index) => {
				if (index === 0) {
					return {
						name: item.behavior,
						axisLabel: { show: true },
						max: Math.max.apply(null, this.departChartData)
					};
				}
				return {
					name: item.behavior,
					max: Math.max.apply(null, this.departChartData)
				};
			});
		},
		async formatData() {
			await this.$nextTick();
			// 圆环
			const score = this.dataObj.totalScore ? this.dataObj.totalScore.toFixed(1) : 0;
			this.ringEchartsOptions.title.text = `{num|${score}}{unit|分}`;
			this.ringEchartsOptions.series[0].data = [
				{ value: 100 - score, itemStyle: { color: "#EEF1F4" } },
				{
					value: score,
					itemStyle: {
						color: this.ringColorMap[this.rankingMap.className]
					}
				}
			];
			const ringEcharts = this.$echarts.init(document.getElementById("ringExportEcharts"));
			ringEcharts.setOption(this.ringEchartsOptions);
			// 柱状图
			const barEcharts = this.$echarts.init(document.getElementById("barExportEcharts"));
			this.barEchartsOptions.series[0].data = this.chartsData;
			this.barEchartsOptions.xAxis.data = this.barEchartsOptionsXData;
			barEcharts.setOption(this.barEchartsOptions);
			// 雷达图
			const radarEcharts = this.$echarts.init(document.getElementById("radarExportEcharts"));
			this.radarEchartsOptions.series[0].data[0].value = this.departChartData;
			this.radarEchartsOptions.radar.indicator = this.departIndicator;
			radarEcharts.setOption(this.radarEchartsOptions);
		}
	}
};
</script>

<style lang="scss" scoped>
.export-report-content {
	background: #fff;
	padding: 22px;
	width: 800px;
	.page-one {
		height: 1042px;
		background: url("~@/assets/departRemind/pg_bg.png") no-repeat;
		background-size: 100% 100%;
		.logo {
			padding: 43px 0 0 38px;
			display: flex;
			align-items: center;
			.logo-1 {
				width: 130px;
				height: 36px;
				background: url("~@/assets/departRemind/logo_1.png") no-repeat;
				background-size: 100% 100%;
			}
			.logo-2 {
				width: 96px;
				height: 36px;
				background: url("~@/assets/departRemind/logo_2.png") no-repeat;
				background-size: 100% 100%;
				margin-left: 20px;
			}
		}
		.company {
			font-size: 40px;
			font-weight: 500;
			color: #262626;
			line-height: 56px;
			letter-spacing: 5px;
			text-align: center;
			margin: 284px 0 24px;
		}
		.title {
			font-size: 60px;
			font-weight: 600;
			color: #000000;
			line-height: 84px;
			letter-spacing: 3px;
			text-align: center;
		}
		.date {
			font-size: 21px;
			color: #262626;
			line-height: 29px;
			margin-top: 380px;
			text-align: center;
		}
	}
	.page-2 {
		margin-top: 50px;
		.card-title {
			display: flex;
			align-items: center;
			padding-bottom: 8px;
			border-bottom: solid 1px #dedfe4;
			.left {
				display: flex;
				align-items: center;
				.icon {
					width: 3px;
					height: 16px;
					background: #c6001a;
					border-radius: 2px;
					margin-right: 5px;
				}
				.text {
					font-size: 16px;
					font-weight: 600;
					color: #262626;
					line-height: 22px;
				}
			}
			.right {
				display: flex;
				align-items: center;
				margin-left: auto;
				.text {
					font-size: 10px;
					color: #333333;
					// line-height: 14px;
				}
				.time-text {
					margin-right: 4px;
				}
				.con-text {
					margin: 0 8px;
				}
				.line {
					display: flex;
					align-items: center;
					margin-right: 30px;
					.value {
						font-weight: 600;
					}
				}
				.level-text {
					font-size: 14px;
					font-weight: 500;
					color: #262626;
					line-height: 20px;
					&.hignli-text {
						color: #0091ff;
					}
				}
			}
		}
		.base-info {
			margin-bottom: 30px;
			.card-content {
				padding: 10px 0 0 10px;
				.table-content {
					display: flex;
					.driver-info {
						display: flex;
						align-items: center;
						flex-wrap: wrap;
						flex: 1;
						border-top: 1px solid #d9d9d9;
						border-left: 1px solid #d9d9d9;
						.info-item {
							width: 50%;

							display: flex;
							align-items: center;
							font-size: 12px;
							color: #8c8c8c;
							line-height: 36px;
							.label {
								width: 126px;
								height: 36px;
								background: #f8f8f8;
								padding-left: 18px;
								border-right: 1px solid #d9d9d9;
								border-bottom: 1px solid #d9d9d9;
							}
							.value {
								height: 36px;
								padding-left: 18px;
								flex: 1;
								color: #262626;
								border-right: 1px solid #d9d9d9;
								border-bottom: 1px solid #d9d9d9;
							}
						}
					}
					.dirver-avator {
						width: 115px;
						height: 145px;
						border: 1px solid #d9d9d9;
						border-left: none;
					}
				}
			}
		}
		.card-right {
			flex: 1;
			margin-left: 45px;
			.table-content {
				border: 1px solid rgba(0, 0, 0, 0.06);
				border-radius: 4px;
				.per-1 {
					width: 10%;
					height: 100%;
					text-align: center;
					border-right: 1px solid rgba(0, 0, 0, 0.06);
				}
				.per-2 {
					width: 40%;
					height: 100%;
					padding: 0 10px;
					border-right: 1px solid rgba(0, 0, 0, 0.06);
				}
				.per-3 {
					width: 20%;
					height: 100%;
					padding: 0 10px;
					border-right: 1px solid rgba(0, 0, 0, 0.06);
				}
				.per-4 {
					width: 30%;
					height: 100%;
					text-align: center;
				}
				.t-head {
					display: flex;
					align-items: center;
					height: 30px;
					background: #f0f0f5;
					font-size: 10px;
					font-weight: 500;
					color: #262626;
					line-height: 30px;
					border-bottom: 1px solid rgba(0, 0, 0, 0.06);
				}
				.t-body {
					.data-item {
						height: 30px;
						display: flex;
						align-items: center;
						font-size: 10px;
						color: #262626;
						line-height: 30px;
						border-bottom: 1px solid rgba(0, 0, 0, 0.06);
						&:last-child {
							border-bottom: none;
						}
						&.bg {
							background: #fafafa;
						}
					}
				}
			}
		}
		.driver-behavior {
			margin-bottom: 30px;
			.card-content {
				display: flex;
				padding-top: 20px;
				.card-left {
					.title {
						font-size: 14px;
						font-weight: 500;
						color: #262626;
						line-height: 20px;
						margin-bottom: 6px;
						text-align: center;
					}
					.bar-echarts {
						width: 380px;
						height: 215px;
					}
				}
			}
		}
		.driver-ranking {
			margin-bottom: 30px;
			.card-content {
				display: flex;
				padding-top: 20px;
				.card-left {
					.radar-echarts {
						width: 380px;
						height: 220px;
						margin-top: 10px;
					}
				}
			}
		}
		.analys-result {
			margin-bottom: 34px;
			.card-content {
				display: flex;
				padding-top: 20px;
				.card-left {
					padding: 30px 50px 0 28px;
					.ring-charts {
						width: 120px;
						height: 120px;
					}
					.title {
						font-size: 15px;
						font-weight: 600;
						color: #000000;
						line-height: 21px;
						margin-top: 20px;
						text-align: center;
					}
				}
				.card-right {
					margin-left: 0;
					.rank-p {
						display: flex;
						align-items: center;
						padding-left: 16px;
						margin-bottom: 16px;
						.rank-item {
							display: flex;
							align-items: center;
							&.rank-right {
								margin-left: 156px;
							}
							.icon {
								width: 13px;
								height: 12px;
								background: url("~@/assets/driver_order_icon.png") no-repeat;
								background-size: 100% 100%;
								margin-right: 6px;
							}
							.label {
								font-size: 12px;
								color: #333333;
								line-height: 16px;
								margin-right: 16px;
							}
							.value {
								font-size: 12px;
								font-weight: 600;
								color: #000000;
								line-height: 16px;
							}
						}
					}
					.result-area {
						background: #eff1f8;
						border-radius: 4px;
						padding: 15px 20px;
						.result-text {
							font-size: 12px;
							font-weight: 500;
							color: #262626;
							line-height: 20px;
						}
						.suggest {
							margin-top: 40px;
							.text {
								font-size: 12px;
								color: #262626;
								line-height: 20px;
							}
						}
					}
				}
			}
		}
		.end-text {
			font-size: 14px;
			color: #262626;
			line-height: 20px;
			text-align: right;
		}
	}
}
</style>
