<template>
	<el-dialog title="详情" :visible.sync="detailDialog" width="1000px" custom-class="driver-dialog">
		<div class="export-btn" @click="downLoadHandle">
			<i class="el-icon-upload2 icon"></i>
			<span>导出</span>
		</div>
		<div class="export-content" id="reportCon">
			<exportCom :rowData="rowData"></exportCom>
		</div>
		<div class="container-area">
			<div class="left-content">
				<div class="base-info">
					<div class="info-item">
						<span class="label-text">所属机构:</span>
						<span class="value-text">{{ rowData.orgName }}</span>
					</div>
					<div class="info-item">
						<span class="label-text">所属线路:</span>
						<span class="value-text">{{ rowData.routeName }}</span>
					</div>
					<div class="info-item">
						<span class="label-text">车牌号:</span>
						<span class="value-text">{{ rowData.vehicleNo }}</span>
					</div>
					<div class="info-item">
						<span class="label-text">司机姓名:</span>
						<span class="value-text">{{ rowData.driverName }}</span>
						<!-- <el-select class="value-text" v-model="driverId" filterable size="small">
							<el-option v-for="(item, index) in driverList" :key="index" :label="item.cName" :value="item.driverId"></el-option>
						</el-select> -->
					</div>
				</div>
				<div class="card-item">
					<p class="title-text">驾驶行为柱状图</p>
					<div class="bar-echarts" id="barEcharts"></div>
				</div>
				<div class="card-item">
					<p class="title-text">驾驶行为能力图</p>
					<div class="radar-echarts" id="radarEcharts"></div>
				</div>
				<div class="des-content">
					<div class="title-content">
						<p class="left">
							<i class="el-icon-warning icon"></i>
							<span class="text">指标解释</span>
						</p>
						<p class="right" @click="showDetailRemark = !showDetailRemark">
							<span class="view-text">{{ showDetailRemark ? "收起" : "查看" }}</span>
							<i class="el-icon-arrow-down" v-if="!showDetailRemark"></i>
							<i class="el-icon-arrow-up" v-else></i>
							<!-- <span class="view-icon"></span> -->
						</p>
					</div>
					<div class="des" v-show="showDetailRemark">
						<p v-for="(item, index) in markTextList" :key="index">
							<span class="label">{{ item.label }}</span>
							<span class="value">{{ item.value }}</span>
						</p>
					</div>
				</div>
			</div>
			<div class="right-content">
				<div class="title-content">
					<p>
						<span class="icon"></span>
						<span class="text">驾驶建议</span>
					</p>
				</div>
				<div class="ring-area">
					<div class="tag-content" :class="rankingMap.className">
						<span class="text">{{ rankingMap.text }}</span>
					</div>
					<div class="ring-content">
						<div class="ring-charts" id="ringEcharts"></div>
						<p class="text">综合得分</p>
					</div>
					<div class="order-content">
						<p>
							<span class="icon"></span>
							<span class="text">在线路的排名：</span>
							<span class="value">{{ rankingData.routeRanking }}/{{ rankingData.routeTotal }}</span>
						</p>
						<p>
							<span class="icon"></span>
							<span class="text">在公司的排名：</span>
							<span class="value">{{ rankingData.companyRanking }}/{{ rankingData.companyTotal }}</span>
						</p>
					</div>
					<div class="des-remark">
						<p>
							通过观察驾驶员的踩油门与刹车的习惯、起步与停车的平稳性等，该时间段内该司机综合得分为【{{
								rowData.vehicleDailyScoreValue || 0
							}}】分；在【{{ rowData.routeName }}】线路中排名【第{{
								rankingData.routeRanking
							}}】，在【吐鲁番昌安公共交通有限公司】中排名【第{{
								rankingData.companyRanking
							}}】，可以初步判断其驾驶技能水平属于【{{ rankingMap.text }}】。<span v-if="rowData.vehicleDailyScoreValue >= 80"
								>建议司机继续保持！</span
							>
						</p>
						<!-- <p v-else>
							通过观察驾驶员的踩油门与刹车的习惯、起步与停车的平稳性等，该时间段内司机综合得分为【{{
								rowData.vehicleDailyScoreValue || 0
							}}】分；在【{{ rowData.busLineName }}】线路中排名【第{{
								rankingData.routeRanking
							}}】，在【吐鲁番昌安公共交通有限公司】中排名【第{{
								rankingData.companyRanking
							}}】，可以初步判断其驾驶技能水平属于【{{ rankingMap.text }}】，建议司机继续保持！
						</p> -->
					</div>
					<div class="suggest-content" v-if="rankingData?.suggestions?.length">
						<p class="sugget-title">建议司机按以下驾驶方式优化：</p>
						<p v-for="(item, index) in rankingData.suggestions || []" :key="index">{{ index + 1 }}.{{ item }}</p>
					</div>
				</div>
			</div>
		</div>
		<!-- <div slot="footer">
			<el-button @click="detailDialog = false">关闭</el-button>
			<el-button type="primary" @click="downLoadHandle">下载报告</el-button>
		</div> -->
	</el-dialog>
</template>

<script>
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import exportCom from "./export.vue";

export default {
	components: {
		exportCom
	},
	data() {
		return {
			keyValueMap: [
				"vehicleRapidAccelerationCount",
				"vehicleRapidDecelerationCount",
				// "vehicleRollingCarDrivingCount",
				// "vehicleWorkErrFreqCount",
				"vehicleOpenDoorDrivingCount",
				"vehicleSpeedingCount",
				"vehicleNeutralAirConditioningCount",
				"vehicleFullThrottleStartCount",
				"vehicleNotReturnNeutralCount"
			],
			chartsData: [],
			rankingData: {},
			markTextList: [
				{ label: "急加速", value: "：是指车速＜30km/h的车速，加速踏板一下踩到≥60%深度的情景；" },
				{ label: "急减速", value: "：是指车速＞10km/h的车速，制动踏板一下踩到≥50%深度的情景；" },
				{ label: "开门行车", value: "：是指车辆的前门或后门是打开状态下，车速≥0.5Km/h的情景；" },
				{ label: "超速行驶", value: "：是指车辆车速≥60Km/h的情景；" },
				{ label: "平均车速", value: "：是指车辆在有车速情况下面的平均速度；" },
				{ label: "空挡空调", value: "：是指车辆档位状态为空挡，空挡是打开状态且电流是10A的情景；" },
				{ label: "满油门起步", value: "：是指车辆车速为0km/h时，加速踏板一下踩到≥90%深度的情景；" },
				{ label: "拉手刹3分钟不回空挡", value: "：是指车辆手刹状态为启动时，3分钟内车辆档位状态不是空挡的情景。" }
			],
			showDetailRemark: false,
			// driverList: [],
			// driverId: "",
			ringEchartsOptions: {
				title: {
					text: "",
					x: "center",
					y: "center",
					textStyle: {
						rich: {
							num: {
								fontWeight: 500,
								color: "#000",
								fontSize: 32
							},
							unit: {
								color: "#767D91",
								fontSize: 21
							}
						}
					}
				},
				legend: {
					show: false
				},
				series: [
					{
						name: "",
						type: "pie",
						radius: ["80%", "90%"],
						avoidLabelOverlap: false,
						hoverAnimation: false,
						label: {
							show: false
						},
						emphasis: {
							scale: false
						},
						labelLine: {
							show: false
						},
						itemStyle: {
							borderRadius: 3
						},
						data: []
					}
				]
			},
			barEchartsOptions: {
				grid: {
					left: 10,
					right: 0,
					bottom: 0,
					top: 30,
					containLabel: true
				},
				tooltip: {
					trigger: "axis",
					axisPointer: {
						type: "shadow"
					}
				},
				xAxis: {
					data: [
						"急加速",
						"急减速",
						// "溜车行驶",
						// "故障行驶",
						"开门行车",
						"超速行驶",
						"空挡空调",
						"满油门起步",
						"拉手刹3分\n钟不回空挡"
					],
					axisLine: {
						lineStyle: {
							color: "rgba(24, 24, 24, 0.25)"
						}
					},
					axisLabel: {
						color: "rgba(0, 0, 0, 0.6)",
						fontSize: 12,
						interval: 0,
						lineHeight: 14
					},
					axisTick: {
						alignWithLabel: true
					}
				},
				yAxis: {
					name: "单位（次）",
					nameTextStyle: {
						color: "rgba(0, 0, 0, 0.4)",
						fontSize: 12
					},
					axisLine: {
						show: false,
						lineStyle: {
							color: "rgba(24, 24, 24, 0.1)"
						}
					},
					axisLabel: {
						color: "rgba(0, 0, 0, 0.4)",
						fontSize: 12
					},
					splitLine: {
						// show: false,
						lineStyle: {
							color: "rgba(24, 24, 24, 0.1)"
						}
					}
				},
				series: [
					{
						type: "bar",
						barWidth: 14,
						itemStyle: {
							normal: {
								color: "#0052D9"
							}
						},
						data: []
					}
				]
			},
			radarEchartsOptions: {
				color: ["#5B8FF9"],
				radar: {
					indicator: [
						{ name: "急加速", max: 10, axisLabel: { show: true } },
						{ name: "急减速", max: 10 },
						// { name: "溜车行驶", max: 10 },
						// { name: "故障行驶", max: 10 },
						{ name: "开门行车", max: 10 },
						{ name: "超速行驶", max: 10 },
						{ name: "空挡空调", max: 10 },
						{ name: "满油门起步", max: 10 },
						{ name: "拉手刹3分钟不回空挡", max: 10 }
					]
				},
				series: [
					{
						name: "",
						type: "radar",
						data: [
							{
								value: []
								// name: "急加速"
							}
						],
						areaStyle: {
							color: "rgba(91, 143, 249, 0.25)"
						}
					}
				]
			},
			ringColorMap: {
				"tag-1": "#F76763",
				"tag-2": "#5B80FE",
				"tag-3": "#5B80FE",
				"tag-4": "#04BDA1"
			}
		};
	},
	props: {
		value: Boolean,
		rowData: {
			type: Object,
			default: () => ({})
		}
	},
	computed: {
		detailDialog: {
			get() {
				return this.value;
			},
			set(val) {
				this.$emit("input", val);
			}
		},
		rankingMap() {
			const value = this.rowData.vehicleDailyScoreValue;
			if (value < 60) {
				return {
					text: "一般",
					className: "tag-1"
				};
			} else if (value >= 60 && value < 80) {
				return {
					text: "良好",
					className: "tag-2"
				};
			} else if (value >= 80 && value < 90) {
				return {
					text: "优秀",
					className: "tag-3"
				};
			} else {
				return {
					text: "非常优秀",
					className: "tag-4"
				};
			}
		}
	},
	mounted() {
		this.init();
	},
	methods: {
		async init() {
			// 获取司机数据
			// await this.getDriverList();
			// this.rowData.vehicleDailyScoreValue = 59;
			// 获取驾驶行为数据
			this.getEchartsData();
			// 排名数据
			this.getOrderData();
			// 初始化图表
			this.formatData();
		},
		/* async getDriverList() {
			const url = "/base/vehicle/queryPage";
			const params = {
				pageIndex: 1,
				pageSize: 10,
				orgId: this.rowData.routeId,
				plateNum: this.rowData.plateNum
				// driverId: this.diaform.driverId
			};
			const res = await this.$http.post(url, params);
			this.driverList = res?.detail?.list[0]?.driverInfos || [];
			console.log(this.driverList, "list");
			this.driverId = this.driverList[0]?.driverId || "";
		}, */
		async getOrderData() {
			const url = "/crrc-palm-bus/drivingBehavior/findRanking";
			const { eventDate, routeId, vehicleFrameNo } = this.rowData;
			const params = {
				eventDate,
				routeId,
				vin: vehicleFrameNo
			};
			const res = await this.$http.post(url, params, { baseType: "base" });
			this.rankingData = res || {};
		},
		getEchartsData() {
			this.chartsData = [];
			this.chartsData = this.keyValueMap.map(item => {
				return this.rowData[item];
			});
		},
		async formatData() {
			await this.$nextTick();
			// 圆环
			const score = this.rowData.vehicleDailyScoreValue || 0;
			this.ringEchartsOptions.title.text = `{num|${score}}{unit|分}`;
			this.ringEchartsOptions.series[0].data = [
				{ value: 100 - score, itemStyle: { color: "#EEF1F4" } },
				{
					value: score,
					itemStyle: {
						color: this.ringColorMap[this.rankingMap.className]
					}
				}
			];
			const ringEcharts = this.$echarts.init(document.getElementById("ringEcharts"));
			ringEcharts.setOption(this.ringEchartsOptions);
			// 柱状图
			const barEcharts = this.$echarts.init(document.getElementById("barEcharts"));
			this.barEchartsOptions.series[0].data = this.chartsData;
			barEcharts.setOption(this.barEchartsOptions);
			// 雷达图
			const radarEcharts = this.$echarts.init(document.getElementById("radarEcharts"));
			this.radarEchartsOptions.series[0].data[0].value = this.chartsData;
			this.radarEchartsOptions.radar.indicator.forEach(item => {
				item.max = Math.max.apply(null, this.chartsData);
			});
			radarEcharts.setOption(this.radarEchartsOptions);
		},
		downLoadHandle() {
			// 获取HTML元素
			const element = document.getElementById("reportCon");
			const options = {};
			// 将元素转换为canvas对象
			html2canvas(element, options).then(canvas => {
				const contentWidth = canvas.width;
				const contentHeight = canvas.height;
				// 创建jsPDF对象
				const pdf = new jsPDF("1", "pt", [contentWidth, contentHeight]); //创建一个新的PDF对象，参数包括页面格式（'1'表示A4纸张）、单位（'pt'）和页面尺寸（[contentWidth, contentHeight]）
				var pageData = canvas.toDataURL("image/jpeg", 1.0); //将Canvas对象转换为JPEG格式的数据，并将其存储在pageData变量中。1.0表示图片质量
				pdf.addImage(pageData, "JPEG", 0, 0, contentWidth, contentHeight); //将JPEG格式的图片添加到PDF文件中，图片的左上角坐标为(0, 0)，宽度为contentWidth，高度为contentHeight
				pdf.save("驾驶行为分析报告.pdf");
			});
		}
	}
};
</script>

<style lang="scss" scoped>
.driver-dialog {
	position: relative;
	.export-btn {
		position: absolute;
		top: 12px;
		right: 76px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 16px;
		font-weight: 500;
		color: #ffffff;
		line-height: 22px;
		height: 36px;
		padding: 0 20px;
		border-radius: 4px;
		border: 1px solid #ffffff;
		cursor: pointer;
		.icon {
			margin-right: 10px;
		}
	}
	.export-content {
		position: fixed;
		top: -9999px;
	}
	.container-area {
		display: flex;
		justify-content: space-between;
		.left-content {
			flex: 1;
			.base-info {
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				margin-bottom: 18px;
				.info-item {
					width: 50%;
					display: flex;
					align-items: center;
					font-size: 14px;
					color: #666666;
					line-height: 28px;
					.label-text {
						width: 60px;
						text-align: right;
					}
					.value-text {
						color: #333333;
						margin-left: 10px;
					}
				}
			}
			.card-item {
				width: 100%;
				background: #ffffff;
				border-radius: 4px;
				border: 1px solid rgba(24, 24, 24, 0.15);
				padding: 26px 28px;
				margin-bottom: 20px;
				.title-text {
					font-size: 16px;
					font-weight: 600;
					color: rgba(0, 0, 0, 0.9);
					line-height: 22px;
				}
				.bar-echarts {
					width: 100%;
					height: 250px;
					margin-top: 20px;
				}
				.radar-echarts {
					height: 300px;
				}
			}
			.des-content {
				background: #fdf1ea;
				border-radius: 4px;
				border: 1px solid rgba(250, 100, 0, 0.5);
				.title-content {
					height: 40px;
					line-height: 40px;
					display: flex;
					align-items: center;
					padding: 0 16px;
					font-size: 14px;
					.left {
						display: flex;
						align-items: center;
						.icon {
							color: #fa6400;
							margin-right: 8px;
						}
						.text {
							color: #262626;
						}
					}
					.right {
						margin-left: auto;
						cursor: pointer;
						.view-text {
							color: #2c68ff;
							margin-right: 10px;
						}
					}
				}
				.des {
					padding: 5px 40px 16px;
					font-size: 12px;
					color: #333333;
					line-height: 20px;
					.label {
						font-weight: 600;
					}
				}
			}
		}
		.right-content {
			width: 292px;
			margin-left: 30px;
			background: #ffffff;
			border-radius: 4px;
			padding-bottom: 16px;
			.title-content {
				padding: 20px 0 0 20px;
				p {
					display: flex;
					align-items: center;
					padding-bottom: 4px;
					border-bottom: solid 1px #dedfe4;
					.icon {
						width: 5px;
						height: 18px;
						background: #2a73ff;
						border-radius: 3px;
						margin-right: 10px;
					}
					.text {
						font-size: 18px;
						font-weight: 600;
						color: #262626;
						line-height: 25px;
					}
				}
			}
			.ring-area {
				padding-top: 18px;
				.tag-content {
					width: 74px;
					height: 40px;
					background: #f0f4ff;
					border-radius: 20px 0 0 20px;
					padding-left: 15px;
					font-size: 16px;
					font-weight: 600;
					color: #5ca0ff;
					line-height: 40px;
					float: right;
					&.tag-1,
					&.tag-2 {
						background: #f0f4ff;
						color: #5ca0ff;
					}
					&.tag-3 {
						background: #e2f8f5;
						color: #00c7aa;
					}
					&.tag-4 {
						padding-left: 6px;
						background: #fff3e4;
						color: #ff710e;
					}
				}
				.ring-content {
					width: 100%;
					margin-bottom: 32px;
					display: flex;
					flex-direction: column;
					align-items: center;
					.ring-charts {
						width: 150px;
						height: 150px;
					}
					.text {
						// width: 100%;
						font-size: 18px;
						font-weight: 600;
						color: #000000;
						line-height: 25px;
						text-align: center;
						margin-top: 20px;
					}
				}
				.order-content {
					margin: 0 20px;
					background: #eff1f8;
					border-radius: 4px;
					padding: 10px 24px;
					margin-bottom: 36px;
					p {
						display: flex;
						align-items: center;
						font-size: 14px;
						line-height: 28px;
						.icon {
							width: 16px;
							height: 14px;
							background: url("~@/assets/driver_order_icon.png") no-repeat;
							background-size: 100% 100%;
							margin-right: 10px;
						}
						.text {
							color: #333333;
						}
						.value {
							margin-left: auto;
							font-weight: 600;
							color: #000000;
						}
					}
				}
				.des-remark {
					padding: 0 20px 0 25px;
					font-size: 14px;
					font-weight: 600;
					color: #262626;
					line-height: 30px;
				}
				.suggest-content {
					margin-top: 40px;
					font-size: 14px;
					color: #262626;
					line-height: 30px;
					padding: 0 20px 0 25px;
					.sugget-title {
						color: #2a73ff;
						font-weight: 600;
					}
				}
			}
		}
	}
}
</style>

<style lang="scss">
.driver-dialog {
	.el-dialog__header {
		padding: 16px 30px;
		background: #2a73ff;
		.el-dialog__title {
			color: #fff;
			font-weight: 600;
			font-size: 20px;
			line-height: 28px;
		}
		.el-dialog__headerbtn .el-dialog__close {
			color: #fff;
		}
	}
	.el-dialog__body {
		padding: 16px 30px 26px;
		background: #f8f9fa;
	}
	.el-dialog__footer {
		padding: 16px 20px;
	}
}
</style>
