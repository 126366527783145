<template>
	<div class="brand">
		<jy-query ref="checkForm" :model="formData" :buttonSpan="3">
			<jy-query-item label="车牌号:" prop="plateNum">
				<el-input placeholder="请输入车牌号" v-model="formData.plateNum"></el-input>
			</jy-query-item>
			<jy-query-item label="司机名称:" prop="driverName">
				<el-input placeholder="请输入司机名称" v-model="formData.driverName"></el-input>
			</jy-query-item>
			<!-- <jy-query-item label="所属机构:" prop="companyName">
				<div @click="selectInstitutions">
					<el-input placeholder="请选择" v-model="formData.companyName" readonly></el-input>
				</div>
			</jy-query-item> -->
			<jy-query-item label="VIN码:" prop="vin">
				<el-input placeholder="请输入VIN码(车架号)" v-model="formData.vin"></el-input>
			</jy-query-item>
			<jy-query-item label="数据时间" prop="range">
				<el-date-picker
					v-model="formData.range"
					type="daterange"
					range-separator="-"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					format="yyyy-MM-dd"
					value-format="yyyyMMdd"
				>
				</el-date-picker>
			</jy-query-item>
			<template slot="search">
				<el-button type="primary" @click="oncheck">查询</el-button>
				<el-button @click="resetForm" type="primary" plain>重置</el-button>
			</template>
			<template slot="operate">
				<el-button type="primary" @click="exportHandle">导出</el-button>
			</template>
		</jy-query>
		<jy-table max-height="595" :data="lists">
			<jy-table-column type="index" width="60" label="序号" align="center"> </jy-table-column>
			<jy-table-column prop="driverName" label="司机名称" min-width="150"> </jy-table-column>
			<jy-table-column prop="vehicleNo" label="车牌号" min-width="120"> </jy-table-column>
			<jy-table-column prop="vehicleFrameNo" label="车架号" min-width="180"> </jy-table-column>
			<jy-table-column prop="orgName" label="所属机构" min-width="220"> </jy-table-column>
			<jy-table-column prop="routeName" label="所属线路" min-width="120"> </jy-table-column>
			<jy-table-column prop="vehicleDailyMileageValue" label="当天里程(km)" min-width="120"> </jy-table-column>
			<jy-table-column prop="vehicleDailyScoreValue" label="总分" min-width="100"> </jy-table-column>
			<jy-table-column prop="vehicleRapidAccelerationCount" label="急加速(次)" min-width="100"> </jy-table-column>
			<jy-table-column prop="vehicleRapidDecelerationCount" label="急减速(次)" min-width="120"> </jy-table-column>
			<jy-table-column prop="vehicleRollingCarDrivingCount" label="溜车行驶" min-width="100"> </jy-table-column>
			<jy-table-column prop="vehicleWorkErrFreqCount" label="故障行驶" min-width="120"> </jy-table-column>
			<jy-table-column prop="vehicleOpenDoorDrivingCount" label="开门行车" min-width="150"> </jy-table-column>
			<jy-table-column prop="vehicleSpeedingCount" label="超速行驶(次)" min-width="120"> </jy-table-column>
			<jy-table-column prop="vehicleAverageSpeedValue" label="平均车速(km/h)" min-width="120"> </jy-table-column>
			<jy-table-column prop="vehicleNeutralAirConditioningCount" label="空挡空调(次)" min-width="120"> </jy-table-column>
			<jy-table-column prop="vehicleFullThrottleStartCount" label="满油门起步次数" min-width="120"> </jy-table-column>
			<jy-table-column prop="vehicleNotReturnNeutralCount" label="拉手刹3分钟不回空挡次数" min-width="200"> </jy-table-column>
			<jy-table-column prop="eventDate" label="数据时间" min-width="100"></jy-table-column>
			<jy-operate :list="operateList" width="80"></jy-operate>
		</jy-table>
		<jy-pagination
			@size-change="handleSizeChange"
			@current-change="handleCurrentChange"
			:current-page="pageNum"
			:page-size="pageSize"
			:total="total"
		>
		</jy-pagination>
		<!-- <institutions-tree
			ref="institutions"
			@addInstitutions="addInstitutions"
			url="/system/org/queryOrgTree"
			title="选择机构"
		></institutions-tree> -->
		<DetailContent v-model="detailDialog" v-if="detailDialog" :rowData="rowData"></DetailContent>
	</div>
</template>
<script>
// import institutionsTree from "@/components/pages/admin/common/dialog-tree/institutions_tree";
import DetailContent from "./detail.vue";
// import dayjs from "dayjs";

export default {
	name: "driverBehavior",
	components: {
		// institutionsTree,
		DetailContent
	},
	data() {
		return {
			formData: {
				driverName: "",
				plateNum: "",
				vin: "",
				range: []
			},
			options: [],
			lists: [],
			pageNum: 1,
			total: 100,
			pageSize: 10,
			operateList: [
				{
					name: "详情",
					fun: this.viewDetail,
					isShow: () => {
						return true;
					}
				}
			],
			rowData: {},
			detailDialog: false
		};
	},
	activated() {
		this.oncheck();
	},
	methods: {
		oncheck() {
			this.pageNum = 1;
			this.getList();
		},
		resetForm() {
			// this.range = [];
			// this.formData = {};
			this.pageNum = 1;
			this.$refs.checkForm.resetFields();
			this.oncheck();
		},
		handleSizeChange(val) {
			this.pageSize = val;
			this.pageNum = 1;
			this.getList();
		},
		handleCurrentChange(val) {
			this.pageNum = val;
			this.getList();
		},
		getParams() {
			const params = {
				...this.formData,
				pageNum: this.pageNum,
				pageSize: this.pageSize,
				startDate: this.formData.range?.length ? this.formData.range[0] : "",
				endDate: this.formData.range?.length ? this.formData.range[1] : ""
			};
			delete params.range;
			return params;
		},
		getList() {
			const url = "/crrc-palm-bus/drivingBehavior/list";
			const params = this.getParams();
			this.$http.post(url, params, { baseType: "base" }).then(res => {
				const { records, total, current } = res;
				this.total = total;
				this.lists = records;
				this.pageNum = current;
			});
		},
		/* // 选择机构树
		selectInstitutions() {
			this.$refs.institutions.init();
		},
		// 上级机构查询
		addInstitutions(data) {
			console.log(data);
			this.formData.orgId = data.orgId;
			this.formData.companyName = data.orgNa;
		}, */
		viewDetail(row) {
			this.rowData = row;
			this.detailDialog = true;
		},
		async exportHandle() {
			const url = "/crrc-palm-bus/drivingBehavior/export";
			const params = this.getParams();
			const res = await this.$http.post(url, params, { baseType: "base", isExcel: true });
			if (res.status == 200) {
				this.$util.funDownload(res.data, "驾驶行为信息");
			} else {
				this.$message({
					message: "导出失败",
					type: "warning"
				});
			}
		}
	}
};
</script>
<style lang="scss" scoped></style>
